import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";

import us from 'locales/en-US.json';
import vn from 'locales/vi-VN.json';
import cn from 'locales/zh-CN.json';
import tw from 'locales/zh-TW.json';
import { COOKIE } from "constants/cookie";

const resources = {
  "zh-TW": { translation: tw },
  "zh-CN": { translation: cn },
  "en-US": { translation: us },
  "vi-VN": { translation: vn },
};

i18n.use(initReactI18next).init({
  resources,
  lng: Cookies.get(COOKIE.LANG) || navigator.language || document.documentElement.lang || "en-US",
  interpolation: {
    escapeValue: false,
  },
  debug: false,
});

export default i18n;
