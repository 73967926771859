import i18n from "../i18n";

export const GATEWAY_CODE_WITHDRAW = {
  三方銀行: "WITHDRAW_GATE_BANK",
  線下銀行: "WITHDRAW_GATE_BANK_OFFLINE",
  三方虛擬: "WITHDRAW_GATE_CRYPTO",
  線下虛擬: "WITHDRAW_GATE_CRYPTO_OFFLINE"
}

export const GATEWAY_CODE_DEPOSIT = {
  三方銀行: "DEPOSIT_GATE_BANK",
  三方超商: "DEPOSIT_GATE_CVS",
  三方ATM: "DEPOSIT_GATE_ATM",
  線下銀行: "DEPOSIT_GATE_BANK_OFFLINE",
  線下虛擬: "DEPOSIT_GATE_CRYPTO_OFFLINE"
}

export enum GATEWAY {
  bankCard = 1,
  crypto = 2
}

export const WITHDRAW_TYPE: any = {
  WITHDRAW_GATE_BANK: i18n.t("thirdParty"),
  WITHDRAW_GATE_BANK_OFFLINE: i18n.t("transfer"),
}