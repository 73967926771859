
// 語系

import { COOKIE } from "constants/cookie";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import i18n from "i18n";

const useLanguage = () => {
  const [userLang, setUserLang] = useState<string>(
    Cookies.get(COOKIE.LANG) || 
    navigator.language || 
    document.documentElement.lang || 
    "en-US"
  );

  useEffect(() => {
    if (userLang) {
      const zone = userLang.split('-');
      document.body.className = zone[0];
    }
  }, [userLang]);

  const list = [
    "zh-TW",
    "zh-CN",
    "en-US",
    "vi-VN"
  ];

  const changeLanguage = (newValue: string) => {
    Cookies.set(COOKIE.LANG, newValue);
    setUserLang(newValue);
    i18n.changeLanguage(newValue);
    window.location.reload();
  }
 
  return {
    list,
    getLang: userLang,
    setLang: changeLanguage
  }
};

export default useLanguage;
