import { MEMBER_BANK_STATE } from "constants/state";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Decimal from 'decimal.js';
import i18n from "i18n";

dayjs.extend(utc);
dayjs.extend(timezone);

// enum 轉成下拉選單(_, 排除項目)
export function enumToOptions(e: any, exclude: any = []) {
  return Object.keys(e)
    .filter(v => isNaN(Number(v)) && !exclude.includes(e[v]))
    .map((k: any) => ({ value: e[k], label: i18n.t(k) }))
}

export function leftPad(str: string, len: number, char: string) {
  while (str.length < len) {
    str = char + str;
  }
  return str;
}

// 欄位驗證
export function verify({
  required = true,
  point = 2,
  max = null,
  min = null,
  negative = false,
  zero = true,
  isShowCompareNumber = false,
  mustMeetPasswordFormat = false
}: any) {
  return [
    { required: required, message: `${i18n.t('required')}` },
    {
      validator: async (record: any, value: any) => {

        let regex = new RegExp('^-?\\d{1,100}(\\.\\d{0,0})?$');
        let msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('onlyIntegersCanBeEntered')}`;

        if (point === 2) {
          regex = new RegExp('^-?\\d{1,100}(\\.\\d{1,2})?$');
          msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('supportsUpToTwoDecimalPlaces')}`;
        } else if (point === 4) {
          regex = new RegExp('^-?\\d{1,100}(\\.\\d{1,4})?$');
          msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('supportsUpToFourDecimalPlaces')}`;
        } else if (point === 5) {
          regex = new RegExp('[a-zA-Z]{1}[a-zA-Z0-9]{3,11}$');
          msg = i18n.t('englishAndNumbersWithin4To12Characters');
        } else if (point === 6) {
          regex = new RegExp('[a-zA-Z0-9*_-]{4,20}$');
          msg = i18n.t('supportSpecialCharacters');
        } else if (point === 7) {
          regex = new RegExp('^[0-9]+$');
          msg = i18n.t('invalidFormat');
        }

        // 只能是數字
        if (!mustMeetPasswordFormat && isNaN(value) && value && point !== 5 && point !== 6 && point !== 7) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 只能是正整數
        if ((!negative && Number(value) < 0) || (point === 0 && !regex.test(value))) {
          return Promise.reject(i18n.t('onlyCanBePositiveIntegers'));
        }

        // 不能為0 但選填
        if (!zero && (typeof value !== 'undefined' && value !== null) && Number(value) === 0) {
          return Promise.reject(`${i18n.t('mustBeGreaterThan')}0`);
        }

        // 正數
        if (!negative && Number(value) < 0) {
          return Promise.reject(i18n.t('contentOnlyNumber'));
        }

        // 小數點
        if (!mustMeetPasswordFormat && value && !regex.test(value)) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 超過最大
        if (isShowCompareNumber && max !== null && parseFloat(value) > parseFloat(max as string)) {
          return Promise.reject(`${i18n.t('mustBeLessThanOrEqualTo')}${max}`);
        }
        if (max !== null && parseFloat(value) > parseFloat(max as string)) {
          return Promise.reject(i18n.t('rateMax'));
        }
        // 限制最小
        if (isShowCompareNumber && min !== null && parseFloat(value) < parseFloat(min as string)) {
          return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${min}`);
        }
        if (min !== null && parseFloat(value) < parseFloat(min as string)) {
          return Promise.reject(i18n.t('rateMin'));
        }
        // 密碼格式判斷 4~20個字符內的英文、數字，支援*_-
        if (mustMeetPasswordFormat) {
          regex = new RegExp('^[a-zA-Z0-9*_-]{4,20}$');
          msg = i18n.t('supportSpecialCharacters');
          if (!regex.test(value)) {
            return Promise.reject(msg);
          }
        }

        return Promise.resolve();
      }
    }
  ];
}

// 多語系切換 i: jsonString o:name string
export function gameNameTransfer(json: string, defaultName: string, lang: string) {
  const data = json ? JSON.parse(json) : [];
  const transfer = data.find((item: { lang: string }) => item.lang === lang);
  return transfer ? transfer.name : defaultName;
}

// 通用時間
export function timeS2L(t: any, format: string = 'YYYY-MM-DD HH:mm:ss') {
  if (!t) return '-';
  return dayjs.tz(`${t}`.includes('Z') ? t : Number(t)).format(format);
}

export function timeS2LDayjs(t: any) {
  return dayjs.tz(`${t}`.includes('Z') ? t : Number(t));
}

// 通用時間
export function timeL2S(timeString: string) {
  return dayjs.tz(timeString).valueOf();
}

// 下拉選單模糊搜尋
export const selectFuzzyFilter = (input: string, option?: { label: string; value: string }) => {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

// BE GM 特規
export function specialProviderName(input: string | any, change: string, changeOther?: string) {
  return input === 'SELF' ? `${change}(${changeOther})` : input
}

// 轉換成百分比 1 => 100
export function convertedToPercentage01(num: number | string): string {
  let decimalValue;
  if (typeof num === 'undefined') return ''
  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    decimalValue = new Decimal(parsedValue);
  }

  decimalValue = new Decimal(num);

  return decimalValue.mul(100).toFixed(2).toString();
}

// 轉換成百分比 100 => 1
export function convertedToPercentage02(num: number | string): string {
  let decimalValue;
  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    if (!isNaN(parsedValue)) {
      decimalValue = new Decimal(parsedValue).toDP(2);
    } else {
      throw new Error('Invalid input: Not a valid number or string that can be parsed into a number.');
    }
  } else if (typeof num === 'number') {
    decimalValue = new Decimal(num).toDP(2);
  } else {
    throw new Error('Invalid input: Input must be a number or a string that can be parsed into a number.');
  }

  return decimalValue.div(100).toString();
}

// 數字格式
export function toFormatNumber(num: any, fraction: number = 4): string {
  const userLocale = navigator.language;
  const toNum = isNaN(num) ? 0 : Number(num);
  const formattedNumber = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(toNum);
  return formattedNumber;
}

// 正的是綠色
export function numColor03(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) < 0)
    return 'color-down-0'
  else if (Number(num) > 0)
    return 'color-up-0'
  else
    return 'color-04'
}

export function stateColorBank(state: number) {
  switch (state) {
    case MEMBER_BANK_STATE.waitVerify:
      return 'color-padding';
    case MEMBER_BANK_STATE.verificationSuccessfulOpen:
      return 'color-pass';
    case MEMBER_BANK_STATE.verificationSuccessfulClose:
      return 'color-pass';
    case MEMBER_BANK_STATE.verificationFailed:
      return 'color-reject';
    case MEMBER_BANK_STATE.delete:
      return 'color-down-0';
  }
}

// 置換unix最後三位為999
export function unixLast999(num: number) {
  const integerPart = Math.floor(num / 1000);
  return (integerPart * 1000) + 999;
}
